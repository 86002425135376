/* eslint-disable */
import { DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import * as XLSX from "xlsx";
import {
    requestPresignedUrlForFile,
    uploadFileWithPresignedUrl,
    publishTemplate
} from '../../apiService';
import '../../App.css';
import DragAndDropFileUploader from '../../components/DragAndDropFileUploader';
import FileUploader from '../../components/FileUploader';
import NotificationDialog from '../../components/NotificationDialog/index.js';
import {
    getEnvironments,
    gets3Bucket,
    getS3ImagePath,
    getTemplatePath,
    getTemplateTypes,
    getTemplateVersion,
    getVehicleModels,
    getVehicleYears,
    isOneToMany,
    isValidFileName,
} from '../../utils';

export default class PublishTemplateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createdTemplate: false,
            displayPublishButton: false,
            images: [],
            open: false,
            s3Images: [],
            template: {},
            templateType: "",
            vehicleModel: "",
            vehicleYear: "",
            vehicleModels: [],
        };
        this.addTemplate = this.addTemplate.bind(this);
        this.addTemplateImage = this.addTemplateImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.showInvalidFileNameDialog = this.showInvalidFileNameDialog.bind(this);
        this.updateTemplate = this.updateTemplate.bind(this);
        this.updateVehicle = this.updateVehicle.bind(this);
        this.updateVehicleYear = this.updateVehicleYear.bind(this);
        this.uploadTemplate = this.uploadTemplate.bind(this);
        this.updateVehicleDropdown = this.updateVehicleDropdown.bind(this);
    }

    uploadTemplate(application) {
        return new Promise((resolve, reject) => {
            let file = this.state.template;
            this.setState({
                spinnerOn: true
            });
            let requestBody
            if(application == 'web'){
                requestBody = {
                    key: getTemplatePath(this.state.templateType) + file.name,
                    bucket: gets3Bucket(true),
                }
            }
            if(application == 'app'){
                if(this.state.templateType == 'Vehicle'){
                    const path = isOneToMany(this.state.vehicleModel) ? "psa_truck/" : "psa_vehicle/" ;
                    requestBody = {
                        key: path + file.name,
                        bucket: gets3Bucket(true),
                    }
                }
                if(this.state.templateType == 'App Walkthrough (App)'){
                    requestBody = {
                        key: getTemplatePath(this.state.templateType) + file.name,
                        bucket: gets3Bucket(true)
                    }
                }

                
            }
            console.log(requestBody)
            requestPresignedUrlForFile(requestBody)
                .then(function (response) {
                    this.setState({
                        templateType: null,
                        open: false,
                        displayPublishButton: false,
                        vehicleModel: null,
                        vehicleYear: null,
                    });
                    const signedUrl = response.data;
                    uploadFileWithPresignedUrl(signedUrl, file.name, file)
                        .then(async function (response) {
                            try {
                                await publishTemplate(requestBody.key);
                                this.setState({
                                    spinnerOn: false,
                                    templateType: null,
                                    open: false,
                                    displayPublishButton: false,
                                    vehicleModel: null,
                                    vehicleYear: null,
                                });
                                this.notificationDialog.handleOpen("Template uploaded! Check the AWS email for the final result.");
                            }
                            catch (err) {
                                this.setState({
                                    spinnerOn: false,
                                    templateType: null,
                                    open: false,
                                    displayPublishButton: false,
                                    vehicleModel: null,
                                    vehicleYear: null,
                                });
                                this.notificationDialog.handleOpen("Error uploading the template. Please try again, then reach out to the dev team if the error persists.");
                                console.log(JSON.stringify(err));
                            }
                        }.bind(this))
                        .catch(function (err) {
                            this.setState({
                                spinnerOn: false,
                                templateType: null,
                                open: false,
                                displayPublishButton: false,
                                vehicleModel: null,
                                vehicleYear: null,
                            });
                            this.notificationDialog.handleOpen("Error uploading the template. Please reach out to the dev team.");
                            console.log(JSON.stringify(err));
                        }.bind(this));
                }.bind(this))
                .catch(function (err) {
                    this.setState({
                        templateType: null,
                        open: false,
                        displayPublishButton: false,
                        vehicleModel: null,
                        vehicleYear: null,
                    });
                    this.notificationDialog.handleOpen("Error uploading the template. Please reach out to the dev team.");
                    console.log(JSON.stringify(err));
                    return (reject);
                }.bind(this));
        })
    }
    
    uploadImage(file, application) {
        return new Promise(
            async (resolve, reject) => {
                let requestBody;
                if(application == 'web'){
                    requestBody = {
                        key: await getS3ImagePath(this.state.vehicleModel, this.state.vehicleYear, this.state.templateType) + file.name,
                        bucket: gets3Bucket(false),
                    }
                } 
                
                requestPresignedUrlForFile(requestBody)
                    .then(function (response) {
                        this.setState({
                            spinnerOn: false,
                            open: false,
                        });
                        const signedUrl = response.data;
                        uploadFileWithPresignedUrl(signedUrl, file.name, file)
                            .then(function (response) {
                                this.setState({
                                    spinnerOn: false,
                                });
                                return resolve(response);
                            }.bind(this));
                    }.bind(this))
                    .catch(function (err) {
                        this.setState({
                            spinnerOn: false,
                        });
                        console.error("Error uploading " + file.name + " " + err);
                        reject(err);
                    });
                return resolve();
            });
    }

    updateTemplate = function (event) {
        let templateType = event.target.value;
        if ([
                "Vehicle",
                "Competitive Advantages",
                "Edge (Legacy)",
                "Head to Head",
                "Head to Head v2",
                "Model Overview (MY21+)"
            ].includes(templateType) && !this.state.vehicleModels.length) {
            this.updateVehicleDropdown();
        }
        if (this.state.vehicleYear) {
            templateType = getTemplateVersion(templateType, this.state.vehicleYear);
        };
        this.setState({
            templateType: templateType
        });
    }

    updateVehicleDropdown = async function () {
        getVehicleModels().then(result => {
            this.setState({
                vehicleModels: result,
            });
        });
    }

    updateVehicle = function (event) {
        this.setState({
            vehicleModel: event.target.value
        });
    }

    updateVehicleYear = function (event) {
        const templateType = getTemplateVersion(this.state.templateType, event.target.value)
        this.setState({
            templateType: templateType,
            vehicleYear: event.target.value
        })
    }

    addTemplate = function (event) {
        const file = event.target.files[event.target.files.length - 1];
        if (file.name.trim().includes('.xlsx')) {
            const reader = new FileReader();
            const component = this;
            reader.onload = function (e) {
                try {
                    const data = e.target.result;
                    const readedData = XLSX.read(data, { type: "binary" });
                    const invalidFile = Object.keys(readedData).length === 2;

                    if (invalidFile) {
                        throw new Error("Invalid file");
                    }
                    component.setState({
                        template: file,
                        displayPublishButton: true
                    });
                } catch (error) {
                    component.setState({
                        template: file,
                        displayPublishButton: false
                    });
                    component.notificationDialog.handleOpen("The template you’re trying to upload is invalid. Please ensure you’re uploading a valid .xlsx file and try again.");
                }
            };
            reader.readAsBinaryString(file);
        } else {
            this.notificationDialog.handleOpen("Please select a .xlsx template.");
        }
    }

    showInvalidFileNameDialog() {
        this.notificationDialog.handleOpen("One or more of the files you selected are formatted incorrectly. File names should include only letters, numbers, underscores, periods, and dashes. Please remove any spaces or special characters and remember to update the file name reference in the template.");
    }

    addTemplateImage = async function (event) {
        let files = event;
        for (let item in files) {
            let file = files[item];
            if (isValidFileName(file.name)) {
                let images = this.state.images;
                images.push(file);
                this.setState({
                    images: images,
                    spinnerOn: true,
                });
                switch(this.state.templateType){
                    case "Vehicle":
                        await this.uploadImage(file,'web');
                        break;
                    case "App Walkthrough (App)":
                        let fileToUpload = file;
                        if (this.state.templateType == 'App Walkthrough (App)'){
                            fileToUpload = new File([file], "walkthrough_" + file.name)
                        }
                        break;
                    default:
                        this.uploadImage(file,'web');

                }
            }
            else {
                this.showInvalidFileNameDialog();
                this.setState({
                    spinnerOn: false,
                })
            }
        }
    }

    removeImage(index) {
        this.state.images.splice(index, 1);
        this.setState({
            images: this.state.images
        });
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    TemplateBody = () => {
        let templateDisplayBody;
        switch (this.state.templateType) {
            case "Vehicle":
            case "Competitive Advantages":
            case "Edge (Legacy)":
            case "Head to Head":
            case "Head to Head v2":
            case "Model Overview (MY21+)":
                templateDisplayBody =
                    <div>
                        {this.ModelSelectionDropdown()}
                        {this.state.vehicleModel && this.VehicleYearSelectionDropdown()}
                        {this.state.vehicleYear  && this.ImageUploadContainer()}
                        {this.state.vehicleYear && this.TemplateUploadContainer()}
                        {this.state.displayPublishButton && this.PublishButton()}
                    </div>
                break;
            case "App Page":
            case "App Walkthrough (App)":
            case "Audio Multimedia":
            case "Beyond Zero":
            case "Certification":
            case "EngageXP":
            case "Feature Lookup":
            case "Homepage v2":
            case "Homepage v3":
            case "Login":
            case "Nitro Overview":
            case "Quick Start Guide":
            case "Recalls and Safety Campaign":
            case "Safety Connect":
            case "Safety Landing":
            case "Service Connect":
            case "SmartPath":
            case "Star Safety System":
            case "TCUV":
            case "Tournament":
            case "Towing & Payload":
            case "TSS":
                templateDisplayBody =
                    <div>
                        {this.ImageUploadContainer()}
                        {this.state.templateType && this.TemplateUploadContainer()}
                        {this.state.displayPublishButton && this.PublishButton()}
                    </div>
                break;
            default:
                templateDisplayBody =
                    <div>
                        {this.state.templateType && this.TemplateUploadContainer()}
                        {this.state.displayPublishButton && this.PublishButton()}
                    </div>
        }

        return templateDisplayBody;
    }

    ModelSelectionDropdown = () => {
        return (
            <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                <p>Select Model:</p>
                <Select value={this.state.vehicleModel} onChange={this.updateVehicle} >
                    {this.state.vehicleModels.map(vehicleModel => {
                        return <MenuItem key={vehicleModel} value={vehicleModel}>{vehicleModel}</MenuItem>
                    })}
                </Select>
            </div>
        );
    }

    VehicleYearSelectionDropdown = () => {
        return (
            <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                <p>Select Model Year:</p>
                <Select value={this.state.vehicleYear} onChange={this.updateVehicleYear} >
                    {getVehicleYears().map(vehicleYear => {
                        return <MenuItem key={vehicleYear} value={vehicleYear}>{vehicleYear}</MenuItem>
                    })}
                </Select>
            </div>
        );
    }

    ImageUploadContainer = () => {
        let userInputPrompt = "Drag and drop or click here to upload images";
        if (this.state.vehicleYear && this.state.vehicleModel) {
            userInputPrompt += " for the " + this.state.vehicleYear + " " + this.state.vehicleModel;
        }
        return (
            <div style={{ marginBottom: "40px" }}>
                <DragAndDropFileUploader
                    description={userInputPrompt}
                    files={this.state.images}
                    addFile={this.addTemplateImage}
                    removeFile={this.removeImage} />
            </div>
        );
    }

    TemplateUploadContainer = () => {
        return (
            <div style={{ marginBottom: "40px" }}>
                <FileUploader
                    onlyXlsx
                    title="Upload Template"
                    reuploadTitle="Upload Different Template"
                    addFile={this.addTemplate} />
            </div>
        );
    }

    PublishButton = () => {
        return (
            <div>
                <Button variant='contained'
                    color='secondary'
                    label={"Publish to " + getEnvironments().sourceEnvironment}
                    onClick={() => { this.setState({ open: true }) }} >Publish to {getEnvironments().sourceEnvironment}</Button>
            </div>
        );
    }

    render() {
        const actions = [
            <Button
                key="buttonCancel"
                color='primary'
                onClick={this.handleClose}
            >Cancel</Button>,
            <Button
                key="buttonUpload"
                color='primary'
                keyboardFocused={true}
                onClick={()=>{
                    switch(this.state.templateType){
                        case "Vehicle":
                            this.uploadTemplate('web');
                            break;
                        case "App Walkthrough (App)":
                            break;
                        default:
                            this.uploadTemplate('web');
                    }
                }}
            >Upload</Button>,
        ];
        return (
            <div style={{ marginBottom: "100px" }}>
                <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px", marginTop: "20px" }}>
                    <p>Select Template Type:</p>
                    <Select value={this.state.templateType} onChange={this.updateTemplate} >
                        {getTemplateTypes().map(templateType => {
                            return <MenuItem key={templateType} value={templateType}>{templateType}</MenuItem>
                        })}
                    </Select>
                </div>

                <Dialog
                    open={this.state.open}
                    onRequestClose={this.handleClose}>
                    <DialogContent>
                        {"Are you sure you want to submit this template to " + getEnvironments().sourceEnvironment + "?"}
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
                <NotificationDialog ref={dialogRef => (this.notificationDialog = dialogRef)} />
                <this.TemplateBody />
                <br />
                {this.state.spinnerOn ? <CircularProgress size={50} /> : null}
            </div>
        );
    }
    
}
